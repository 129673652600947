// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED
import { defineComponent, PropType } from 'vue'
import { IPageInfo } from '@/_typings/interfaces/paging'
import CardC from '@/components/controls/card-c/index.vue'
import Pager from '@/components/controls/pager/index.vue'
import SearchInput from '@/components/controls/search-input/index.vue'

export default defineComponent({
  name: 'card-table',
  components: {
    CardC,
    Pager,
    SearchInput
  },
  props: {
    deleteEnabledItemIds: { type: Array as PropType<string[]>, default: () => { return [] }},
    deleteVisibleItemIds: { type: Array as PropType<string[]>, default: () => { return [] }},
    hasHeaderContent: { type: Boolean, default: false },
    hasNoDataNotification: { type: Boolean, default: false },
    hasSummaryContent: { type: Boolean, default: false },
    hasTableActions: { type: Boolean, default: false },
    iconClass: { type: String, default: null },
    isEnabled: { type: Boolean, default: true },
    isItemSelectable: { type: Boolean, default: false },
    isSearchable: { type: Boolean, default: false },
    items: { type: Array, default: () => [] },
    pageInfo: { type: Object as PropType<IPageInfo>, default: () => { return {} } },
    selectedItems: { type: Array as PropType<any[]>, default: () => [] },
    title: { type: String, default: null }
  },
  emits: [ 'change-paged-items', 'change-selected-items', 'add-item', 'delete-item' ],
  computed: {
    selectedItemIds() : string[] {
      return this.selectedItems.map(selectedItem => selectedItem.id)
    }
  },
  methods: {
    changePageIndex(pageIndex: number) {
      this.$emit('change-paged-items', { ...this.pageInfo, pageNumber: pageIndex })
    },
    changePageSize(pageSize: number) {
      this.$emit('change-paged-items', { ...this.pageInfo, pageNumber: 1, pageSize: pageSize })
    },
    isItemDeleteEnabled(item: any) {
      return this.deleteEnabledItemIds.includes(item.id)
    },
    isItemDeleteVisible(item: any) {
      return this.deleteVisibleItemIds.includes(item.id)
    },
    isItemSelected(item: any) {
      return this.selectedItemIds.includes(item.id)
    },
    searchItems(searchText: string) {
      this.$emit('change-paged-items', { ...this.pageInfo, pageNumber: 1, search: searchText })
    },
    selectItem(item: any) {
      this.$emit('change-selected-items', [...this.selectedItems, ...[item]])
    },
    unselectItem(item: any) {
      this.$emit('change-selected-items', this.selectedItems.filter(selectedItem => selectedItem.id !== item.id))
    }
  }
})
